import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  removeMaterialComponents,
  configMaterialComponents,
} from "../../../../utils/MaterialFunctions";
import {
  getGlobalVariables,
  updateGlobalVariables,
} from "../../../../actions/globalActons";

import NavbarAdmin from "../../../layout/NewNavbarAdmin";
import TextInputField from "../../../common/TextInputField";
import Spinner from "../../../common/Spinner";
import isEmpty from "../../../../actions/isEmpty";

class GlobalVariables extends Component {
  state = {
    impuesto: "",
    rtn: "",
    correo: "",
    facturacion_de: "",
    titulo_facturacion: "",
    direccion_facturacion: "",
    telefono_facturacion: "",
    correo_facturacion: "",
    titulo_impresion: "",
    numero_factura: "",
    fecha_limite_emision: "",
    no_desde: "",
    no_hasta: "",
    rango_autorizado: "",
    cai: "",
    loading: true,
  };

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getGlobalVariables();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.global.values &&
      !nextProps.global.loading &&
      !isEmpty(nextProps.global.values) &&
      this.state.loading
    ) {
      let { impuesto, rtn, correo, facturacion } = nextProps.global.values;
      if (!facturacion || Object.keys(facturacion).length === 0) {
        facturacion = {};
      }
      this.setState({
        impuesto,
        rtn,
        correo,
        loading: false,
        titulo_facturacion: facturacion.titulo_facturacion || "",
        direccion_facturacion: facturacion.direccion_facturacion || "",
        titulo_impresion: facturacion.titulo_impresion || "",
        numero_factura: facturacion.numero_factura || "",
        no_desde: facturacion.no_desde || "",
        no_hasta: facturacion.no_hasta || "",
        rango_autorizado: facturacion.rango_autorizado || "",
        cai: facturacion.cai || "",
        facturacion_de: facturacion.facturacion_de || "",
        telefono_facturacion: facturacion.telefono_facturacion || "",
        correo_facturacion: facturacion.correo_facturacion || "",
        fecha_limite_emision: facturacion.fecha_limite_emision || "",
      });
    }
  }

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onSaveValues = () => {
    const {
      impuesto,
      rtn,
      correo,
      titulo_facturacion,
      direccion_facturacion,
      titulo_impresion,
      numero_factura,
      no_desde,
      no_hasta,
      rango_autorizado,
      cai,
      facturacion_de,
      telefono_facturacion,
      correo_facturacion,
      fecha_limite_emision,
    } = this.state;
    this.props.updateGlobalVariables({
      impuesto,
      rtn,
      correo,
      facturacion: {
        titulo_facturacion,
        direccion_facturacion,
        titulo_impresion,
        numero_factura,
        no_desde,
        no_hasta,
        rango_autorizado,
        cai,
        facturacion_de,
        telefono_facturacion,
        correo_facturacion,
        fecha_limite_emision,
      },
    });
    this.setState({ loading: true });
  };

  render() {
    const {
      rtn,
      impuesto,
      correo,
      titulo_facturacion,
      direccion_facturacion,
      titulo_impresion,
      numero_factura,
      no_desde,
      no_hasta,
      rango_autorizado,
      cai,
      facturacion_de,
      telefono_facturacion,
      correo_facturacion,
      fecha_limite_emision,
    } = this.state;
    return (
      <React.Fragment>
        <NavbarAdmin>
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Valores Globales
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSaveValues}>
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NavbarAdmin>

        <main>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  {this.props.global.loading && <Spinner fullWidth />}
                  <div className="row">
                    <TextInputField
                      id="rtn"
                      label="RTN de empresa"
                      onchange={this.onChangeTextInput}
                      value={rtn}
                      active_label={true}
                    />
                  </div>

                  <div className="row">
                    <TextInputField
                      id="correo"
                      label="Correo de empresa"
                      onchange={this.onChangeTextInput}
                      value={correo}
                      active_label={true}
                    />
                  </div>

                  <div className="row">
                    <TextInputField
                      id="impuesto"
                      label="Impuesto para ventas"
                      onchange={this.onChangeTextInput}
                      value={impuesto}
                      type="number"
                      active_label={true}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <div className="card-title">
                    Valores para facturaci&oacute;n
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <TextInputField
                        id="titulo_facturacion"
                        label="Titulo de factura"
                        onchange={this.onChangeTextInput}
                        value={titulo_facturacion}
                        active_label={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <TextInputField
                        id="facturacion_de"
                        label="De Facturacion"
                        onchange={this.onChangeTextInput}
                        value={facturacion_de}
                        active_label={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <TextInputField
                        id="direccion_facturacion"
                        label="Direccion de facturacion"
                        onchange={this.onChangeTextInput}
                        value={direccion_facturacion}
                        active_label={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 m6">
                      <TextInputField
                        id="telefono_facturacion"
                        label="Telefono facturacion"
                        onchange={this.onChangeTextInput}
                        value={telefono_facturacion}
                        active_label={true}
                      />
                    </div>
                    <div className="col s12 m6">
                      <TextInputField
                        id="correo_facturacion"
                        label="Correo facturacion"
                        onchange={this.onChangeTextInput}
                        value={correo_facturacion}
                        active_label={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <TextInputField
                        id="titulo_impresion"
                        label="Titulo de impresion"
                        onchange={this.onChangeTextInput}
                        value={titulo_impresion}
                        active_label={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 m6">
                      <TextInputField
                        id="numero_factura"
                        label="Numero de factura"
                        onchange={this.onChangeTextInput}
                        value={numero_factura}
                        active_label={true}
                      />
                    </div>
                    <div className="col s12 m6">
                      <div className="row">
                        <div className="col s6">
                          <TextInputField
                            id="no_desde"
                            label="N# desde"
                            onchange={this.onChangeTextInput}
                            value={no_desde}
                            active_label={true}
                          />
                        </div>
                        <div className="col s6">
                          <TextInputField
                            id="no_hasta"
                            label="N# hasta"
                            onchange={this.onChangeTextInput}
                            value={no_hasta}
                            active_label={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 m6">
                      <TextInputField
                        id="rango_autorizado"
                        label="Rango autorizado"
                        onchange={this.onChangeTextInput}
                        value={rango_autorizado}
                        active_label={true}
                      />
                    </div>
                    <div className="col s12 m6">
                      <TextInputField
                        id="cai"
                        label="CAI"
                        onchange={this.onChangeTextInput}
                        value={cai}
                        active_label={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12 m6">
                      <TextInputField
                        id="fecha_limite_emision"
                        label="Fecha limite de emision"
                        onchange={this.onChangeTextInput}
                        value={fecha_limite_emision}
                        active_label={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

GlobalVariables.propTypes = {
  global: PropTypes.object.isRequired,
  getGlobalVariables: PropTypes.func.isRequired,
  updateGlobalVariables: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  global: state.global,
});

export default connect(mapStateToProps, {
  getGlobalVariables,
  updateGlobalVariables,
})(GlobalVariables);
