import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "./Spinner";
import uuid from "uuid";

import { getModalInstanceById } from "../../utils/MaterialFunctions";
import { getNumberFormatted, API_URL } from "../../utils/stringUtils";

import isEmpty from "../../actions/isEmpty";
import EmptyIcon from "../common/EmptyIcon";
import { Link } from "react-router-dom";

import ShowProductsClientSells from "../layout/modals/ShowProductClientSells";
import ConfirmationModal from "../layout/modals/ConfirmationModal";

class ShowProduct extends Component {
  onDeleteProductClick = () => {
    getModalInstanceById("modal_confirmar_evento").open();
  };

  onMarkFreezeProduct = () => {
    const {
      product: { congelado },
      onFreezeProduct,
    } = this.props;
    if (onFreezeProduct) {
      onFreezeProduct(!congelado);
    }
  };

  getProductStatus = (status) => {
    let productStatus = null;
    if (isEmpty(status)) {
      return productStatus;
    }
    switch (status) {
      case "ST_INACTIVE":
        productStatus = (
          <span>
            Inactivo
            <div
              className="circle-element red bordered right"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
        );
        break;

      case "ST_ORDERED":
        productStatus = (
          <span>
            Pendiente de entrega
            <div
              className="circle-element amber bordered right"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
        );
        break;
      default:
        break;
    }
    return productStatus;
  };

  getPedidosContent = (pedidos) => {
    let pedidosContent = null;
    if (isEmpty(pedidos)) {
      return pedidosContent;
    }

    pedidosContent = (
      <div className="card">
        <div className="card-content">
          <h5>Pedidos</h5>
          {pedidos.map((pedido) => {
            const { proveedor, local_solicitado, cantidad, id } = pedido;
            let producto_procedencia = null;
            if (proveedor) {
              producto_procedencia = `Proveedor: ${proveedor.nombre} `;
              if (proveedor.rtn) {
                producto_procedencia += `RTN: ${proveedor.rtn}}`;
              }
            } else if (local_solicitado) {
              producto_procedencia = `Local: ${local_solicitado.nombre} `;
              if (local_solicitado.codigo) {
                producto_procedencia += `- ${local_solicitado.codigo}`;
              }
            }

            if (!this.props.admin_area) {
              return (
                <Link
                  className="d-block p-1 border-bottom"
                  to={`/pedidos/${id}`}
                  key={uuid()}
                >
                  {cantidad} de {producto_procedencia}
                </Link>
              );
            } else {
              return (
                <span className="d-block p-1 border-bottom" key={uuid()}>
                  {cantidad} de {producto_procedencia}
                </span>
              );
            }
          })}
        </div>
      </div>
    );
    return pedidosContent;
  };

  render() {
    const { loading, onDeleteProduct, product, admin_area } = this.props;
    let productContent;

    if (loading) {
      productContent = <Spinner fullWidth />;
    } else if (!isEmpty(product)) {
      const {
        id,
        codigo_barra,
        nombre,
        marca,
        tipo_vehiculo,
        descripcion,
        raro,
        precio,
        existencia,
        ubicacion,
        cantidad_minima,
        fecha_creado,
        sin_inventario_desde,
        ultimas_ventas,
        congelado,
        distribucion,
        imagenes,
        pedidos,
        status,
      } = product;
      let distribucionContent;
      let imagesContent;
      let shareImageContent;

      if (distribucion) {
        distribucionContent = (
          <div className="card">
            <div className="card-content">
              <h5>Distribucion</h5>
              <table className="table-bordered table-bold">
                <thead>
                  <tr>
                    <th>Local</th>
                    <th>Existencia</th>
                    <th>Cantidad minima</th>
                    <th>Ubicacion</th>
                    <th></th>
                  </tr>
                </thead>

                {distribucion.length > 0 && (
                  <tbody>
                    {distribucion.map((dist) => (
                      <tr key={uuid()}>
                        <td>{dist.local && dist.local.nombre}</td>
                        <td>{dist.existencia}</td>
                        <td>{dist.cantidad_minima}</td>
                        <td>{dist.ubicacion}</td>
                        <td>
                          <a
                            href={`whatsapp://send?text=${nombre}%0a %0aCodigo: ${codigo_barra}%0aCantidad: ${dist.existencia} %0aUbicacion: ${dist.ubicacion}`}
                          >
                            <img
                              src="http://icons.iconarchive.com/icons/dtafalonso/android-l/512/WhatsApp-icon.png"
                              alt=""
                              width="30px"
                              height="30px"
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        );
      }
      if (imagenes && imagenes.length > 0) {
        imagesContent = (
          <div className="w-100">
            <div className="horizontal-scroll-container">
              {imagenes.map((img) => (
                <div className="img-item" key={uuid()}>
                  <img src={img.url} className="materialboxed adjust" alt="" />
                </div>
              ))}
            </div>
          </div>
        );
      }
      shareImageContent = (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
          }}
        >
          {imagenes.length > 0 && (
            <a
              href={`whatsapp://send?text=${nombre} ${API_URL}/share/image/${imagenes[0].id_archivo}`}
              className="btn teal darken-2"
              style={{
                color: "white",
                display: "flex",
                alignItems: "center",
                width: "max-content",
                marginRight: "10px",
              }}
            >
              <i className="material-icons">call</i>
              <span>Compartir Imagen</span>
            </a>
          )}

          <a
            href={`whatsapp://send?text=${nombre} ${window.location.href}`}
            className="btn"
            style={{
              color: "white",
            }}
          >
            Compartir enlace de producto
          </a>
        </div>
      );
      productContent = (
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              {imagesContent}
              {shareImageContent}
              <table className="table-bordered">
                <tbody>
                  <tr>
                    <td>Id</td>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <td>Codigo de barra</td>
                    <td>{codigo_barra}</td>
                  </tr>
                  <tr>
                    <td>Nombre</td>
                    <td>{nombre}</td>
                  </tr>
                  {marca && (
                    <tr>
                      <td>Marca</td>
                      <td>{marca.nombre}</td>
                    </tr>
                  )}
                  {tipo_vehiculo && (
                    <tr>
                      <td>Tipo de vehiculo</td>
                      <td>{tipo_vehiculo.nombre}</td>
                    </tr>
                  )}
                  {ubicacion && (
                    <tr>
                      <td>Ubicacion</td>
                      <td>{ubicacion}</td>
                    </tr>
                  )}
                  {descripcion && descripcion.trim() !== "" && (
                    <tr>
                      <td>Descripcion</td>
                      <td>{descripcion}</td>
                    </tr>
                  )}

                  <tr>
                    <td>Es raro</td>
                    <td>{raro ? "Si" : "No"}</td>
                  </tr>
                  {precio && (
                    <tr>
                      <td>Precio</td>
                      <td>{getNumberFormatted(precio)}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Existecia</td>
                    <td>{existencia}</td>
                  </tr>
                  {ultimas_ventas && (
                    <tr>
                      <td>Ventas en los ultomos 30 dias</td>
                      <td>{ultimas_ventas}</td>
                    </tr>
                  )}
                  {status && (
                    <tr>
                      <td>Estatus</td>
                      <td>{this.getProductStatus(status)}</td>
                    </tr>
                  )}
                  {congelado === true && (
                    <tr>
                      <td>Congelado</td>
                      <td>
                        <div
                          className="circle-element blue bordered"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </td>
                    </tr>
                  )}
                  {sin_inventario_desde && (
                    <tr>
                      <td>Sin inventario desde</td>
                      <td>{sin_inventario_desde}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Cantidad minima</td>
                    <td>{cantidad_minima}</td>
                  </tr>
                  <tr>
                    <td>Fecha creado</td>
                    <td>{fecha_creado}</td>
                  </tr>
                </tbody>
              </table>
              <button
                className="btn mt-1 mb-1 modal-trigger"
                data-target="modal_product_cliente_ventas"
              >
                Observar precios a clientes
              </button>
            </div>
          </div>
          {this.getPedidosContent(pedidos)}
          {distribucionContent}
          {onDeleteProduct && (
            <button
              className="btn red darken-3"
              onClick={this.onDeleteProductClick}
            >
              Borrar
            </button>
          )}

          {admin_area && (
            <button className="btn ml-1" onClick={this.onMarkFreezeProduct}>
              {congelado ? "Desmarcar como congelado" : "Marcar como congelado"}
            </button>
          )}
        </div>
      );
    } else {
      productContent = <EmptyIcon message="No hay informacion para mostrar" />;
    }
    return (
      <React.Fragment>
        {productContent}

        {onDeleteProduct && (
          <ConfirmationModal
            onAccept={onDeleteProduct}
            title="Borrar producto"
            message="Esta seguro de borrar este producto? No sera posible deshacer la accion."
          />
        )}

        <ShowProductsClientSells product_id={product.id} />
      </React.Fragment>
    );
  }
}
ShowProduct.propTypes = {
  product: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  admin_area: PropTypes.bool.isRequired,
  onDeleteProduct: PropTypes.func,
  onFreezeProduct: PropTypes.func,
};

ShowProduct.defaultProps = {
  onDeleteProduct: null,
  admin_area: false,
};

export default ShowProduct;
