import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { connect } from "react-redux";

import { getModalInstanceById } from "../../../utils/MaterialFunctions";
import { getGlobalVariables } from "../../../actions/globalActons";
import { getNumberFormatted, numberToLetters } from "../../../utils/stringUtils";
import { printSale } from "../../../utils/printPdf";
import { dateToObject } from "../../../utils/dateFormat";
import getFilesFromInput from "../../../utils/getFilesFromInput";

import TextInputField from "../../common/TextInputField";
import { autosizeTextAreaHeight } from "../../../utils/documentUtils";

let state_updating_height = false;
let state_update_components = true;

class PrintSaleModal extends Component {
  state = {
    url_logo: "https://rimeim.com/files/icons/logo_rimeim.png",
    empresa_rtn: "05011982038618",
    empresa_nombre: "RIMEIM",
    empresa_nombre_print: "",
    empresa_titulo_facturacion: "",
    empresa_facturacion_de: "",
    empresa_direccion_facturacion: "",
    empresa_telefono_facturacion: "",
    empresa_correo_facturacion: "",
    empresa_ubicacion: "",
    empresa_telefono: "SPS +504 9481-4706 | Tegus +504 9751-2044 Honduras C.A",
    empresa_email: "",
    empresa_cai: "",
    empresa_rango_autorizado: "",
    empresa_titulo_impresion: "",
    empresa_numero_factura: "",
    empresa_fecha_limite_emision: "",
    cliente_nombre: "",
    cliente_rtn: "",
    cliente_direccion: "",
    no_orden_compra: "",
    no_orden_compra_excenta: "",
    no_constancia_registro_exonerado: "",
    no_registro_sag: "",
    valor_en_letras: "",
    is_printing: false,
    is_editing: true,
    show_client_copy: false,
  };

  client_setted = false;

  componentDidMount() {
    this.props.getGlobalVariables();
    state_update_components = true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.global && !nextProps.global.loading) {
      const { currentLocal } = this.props.user;
      let {
        empresa_rtn,
        empresa_email,
        empresa_telefono,
        empresa_nombre,
        empresa_cai,
        empresa_rango_autorizado,
        empresa_titulo_impresion,
        empresa_numero_factura,
        empresa_facturacion_de,
        empresa_correo_facturacion,
        empresa_telefono_facturacion,
        empresa_titulo_facturacion,
        empresa_direccion_facturacion,
        empresa_fecha_limite_emision,
      } = this.state;

      if (currentLocal.correo) {
        empresa_email = currentLocal.correo;
      } else if (nextProps.global.values.correo) {
        empresa_email = nextProps.global.values.correo;
      }

      if (currentLocal.codigo === "002" && currentLocal.nombre) {
        empresa_nombre = currentLocal.nombre;
      }

      if (currentLocal.rtn) {
        empresa_rtn = currentLocal.rtn;
      } else if (nextProps.global.values.rtn) {
        empresa_rtn = nextProps.global.values.rtn;
      }

      if (currentLocal.telefono) {
        empresa_telefono = currentLocal.telefono;
      }

      if (
        nextProps.global.values.facturacion &&
        Object.keys(nextProps.global.values.facturacion).length > 0
      ) {
        const {
          cai,
          titulo_impresion,
          numero_factura,
          rango_autorizado,
          facturacion_de,
          telefono_facturacion,
          correo_facturacion,
          titulo_facturacion,
          direccion_facturacion,
          fecha_limite_emision,
        } = nextProps.global.values.facturacion;
        empresa_cai = cai || "";
        empresa_rango_autorizado = rango_autorizado || "";
        empresa_titulo_impresion = titulo_impresion || "";
        empresa_numero_factura = numero_factura || "";
        empresa_facturacion_de = facturacion_de || "";
        empresa_telefono_facturacion = telefono_facturacion || "";
        empresa_correo_facturacion = correo_facturacion || "";
        empresa_titulo_facturacion = titulo_facturacion || "";
        empresa_direccion_facturacion = direccion_facturacion || "";
        empresa_fecha_limite_emision = fecha_limite_emision || "";
      }
      this.setState({
        empresa_rtn,
        empresa_email,
        empresa_telefono,
        empresa_nombre,
        empresa_nombre_print: empresa_nombre,
        empresa_cai,
        empresa_rango_autorizado,
        empresa_titulo_impresion,
        empresa_numero_factura,
        empresa_facturacion_de,
        empresa_correo_facturacion,
        empresa_telefono_facturacion,
        empresa_titulo_facturacion,
        empresa_direccion_facturacion,
        empresa_fecha_limite_emision,
      });
    }
    if (nextProps.venta && nextProps.venta.cliente) {
      let { nombre, rtn } = nextProps.venta.cliente;
      if (!nombre) nombre = "";
      if (!rtn) rtn = "";
      this.setState({
        cliente_nombre: nombre,
        cliente_rtn: rtn,
      });
    }

    if (nextProps.venta && nextProps.venta.local) {
      const { ubicacion } = nextProps.venta.local;
      if (ubicacion) {
        this.setState({ empresa_ubicacion: ubicacion });

      }
    }

    if (nextProps.venta && nextProps.venta.values && nextProps.venta.values.total && this.state.valor_en_letras.length === 0) {
      this.setState({
        valor_en_letras: numberToLetters(parseFloat(nextProps.venta.values.total)).toUpperCase()
      })
    }
  }

  shouldComponentUpdate(nextProps, nextStat) {
    return state_update_components;
  }

  componentDidUpdate() {
    if (this.state.is_printing) {
      this.setState({
        is_printing: false,
      });
      printSale("div_print_cotizacion", () => {
        this.setState({
          is_editing: true,
        });
      });
    }

    if (
      this.props.venta.productos &&
      this.props.venta.productos.productos &&
      this.props.venta.productos.productos.length > 0 &&
      !state_updating_height
    ) {
      state_updating_height = true;
      setTimeout(() => {
        state_update_components = false;
        this.adjustAllItems();
        state_updating_height = false;
      }, 1000 * 5);
    }
  }

  onChangeTextInput = (e) => {
    state_update_components = true;
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFiles = (e) => {
    getFilesFromInput(e, (files) => {
      this.setState({ files });
    });
  };

  hideModal = () => {
    getModalInstanceById(this.props.modal_id).close();
  };

  onShowProofDelivery = () => {
    state_update_components = true;
    this.setState({
      show_client_copy: !this.state.show_client_copy,
    });
  };

  onCancelPrint = () => {
    this.hideModal();
    this.props.onCancel();
  };

  onPrintClick = () => {
    this.adjustAllItems();
    state_update_components = true;
    this.setState({
      is_editing: false,
      is_printing: true,
    });
  };

  adjustAllItems = () => {
    if (this.props.venta.productos && this.props.venta.productos.productos) {
      const { productos } = this.props.venta.productos;
      if (productos.length > 0) {
        state_update_components = false;
        productos.forEach((p) =>
          this.adjustHeight(`txt_area${p.id || p.id_producto}`, 20)
        );
      }
    }
  };

  adjustHeight = (
    id_element,
    minHeight,
    save_value = false,
    update_components = false
  ) => {
    const textArea = document.getElementById(id_element);
    if (textArea) {
      autosizeTextAreaHeight(id_element, minHeight);
      const id_producto = id_element.replace("txt_area", "");
      if (save_value) {
        const productoIndex = this.props.venta.productos.productos.findIndex(
          (p) => p.id === id_producto || p.id_producto === id_producto
        );
        if (productoIndex >= 0) {
          this.props.venta.productos.productos[productoIndex].nombre =
            textArea.value.trim();
        }
      }
      if (update_components) {
        state_update_components = true;
      }
    }
  };

  getQuoteData = () => {
    let {
      venta: { productos, values, id, credito, fecha, no_factura },
    } = this.props;
    const {
      empresa_rtn,
      empresa_cai,
      empresa_rango_autorizado,
      empresa_titulo_impresion,
      empresa_numero_factura,
      cliente_nombre,
      cliente_rtn,
      cliente_direccion,
      is_editing,
      show_client_copy,
      valor_en_letras,
      empresa_titulo_facturacion,
      empresa_facturacion_de,
      empresa_correo_facturacion,
      empresa_telefono_facturacion,
      empresa_direccion_facturacion,
      empresa_fecha_limite_emision,
      no_orden_compra,
      no_orden_compra_excenta,
      no_constancia_registro_exonerado,
      no_registro_sag
    } = this.state;
    let clientContent, productsContent, localContent;

    if (!id) {
      id = Math.floor(Math.random() * (2000 - 600 + 1) + 600);
    }

    const currentDate = dateToObject(fecha);
    clientContent = (
      <div className="cotizacion_cliente">
        <div className="cotizacion_cliente_info">
          <span className="line_on_bottom item">
            Cliente: {cliente_nombre || ""}
          </span>
          <div className="cliente_info item">
            <span>R.T.N: {cliente_rtn || ""}</span>
            <span>Direcci&oacute;n: {cliente_direccion || ""}</span>
          </div>
        </div>
        <div className="cotizacion_cliente_fecha">
          <div className="casilla-value">
            <span className="title">Dia</span>
            <span className="value">{currentDate.day}</span>
          </div>
          <div className="casilla-value">
            <span className="title">Mes</span>
            <span className="value">{currentDate.month}</span>
          </div>
          <div className="casilla-value">
            <span className="title">A&ntilde;o</span>
            <span className="value">{currentDate.year}</span>
          </div>
          <div className="casilla-value">
            <span className="title">Orden de compra</span>
            <span className="value">No {no_orden_compra}</span>
          </div>
        </div>
      </div>
    );
    if (productos.productos) {
      productsContent = productos.productos.map((producto) => (
        <tr key={uuid()}>
          <td>{producto.cantidad}</td>

          <td>
            {is_editing ? (
              <textarea
                className="browser-default input-row-transparent overflowy-hidden"
                defaultValue={producto.nombre}
                id={`txt_area${producto.id || producto.id_producto}`}
                onInput={this.adjustHeight.bind(
                  this,
                  `txt_area${producto.id || producto.id_producto}`,
                  20,
                  true,
                  true
                )}
              />
            ) : (
              <span>{producto.nombre}</span>
            )}
          </td>
          <td>L. {getNumberFormatted(producto.precio)}</td>
          <td></td>
          <td>
            L. {getNumberFormatted(producto.cantidad * producto.precio)}
          </td>
          <td></td>
        </tr>
      ));
    }

    localContent = (
      <div className="cotizacion_header mt-1">
        <div className="cotizacion_image">
          <img src={this.state.url_logo} alt="" className="image" />
        </div>
        <div className="cotizacion_text">
          <span className="d-block">{empresa_titulo_facturacion}</span>
          <span className="d-block">{empresa_facturacion_de}</span>
          <span className="d-block">{empresa_direccion_facturacion}</span>
          <span className="d-block">{empresa_telefono_facturacion}</span>
          <span className="d-block">{empresa_correo_facturacion}</span>
          <span className="d-block">RTN: {empresa_rtn}</span>
        </div>
      </div>
    );

    return (
      <div id="div_print_cotizacion" className="div_reporte div_print_sale">
        {localContent}
        {clientContent}
        <table className="table-stripped table-bordered table-venta">
          <thead>
            <tr>
              <th className="cant-th">CANT.</th>
              <th>DESCRIPCI&Oacute;N</th>
              <th colSpan={2}>PRECIO UNIT.</th>
              <th colSpan={2}>TOTAL.</th>
            </tr>
          </thead>
          <tbody>
            {productsContent}
            <tr>
              <td rowSpan={2} colSpan={2}>
                <div className="datos_exonerados">
                  <span className="bottom_border">
                    DATOS DEL ADQUIRIENTE EXONERADO
                  </span>
                  <span>No. de Orden de Compra Excenta: {no_orden_compra_excenta}</span>
                </div>
              </td>
              <td colSpan={2}>Importe Gravado 15%</td>
              <td>L. {getNumberFormatted(parseFloat(values.subtotal) || 0)}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>Importe Gravado 18%</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>No. Constancia de registro de exonerado: {no_constancia_registro_exonerado}</td>
              <td colSpan={2}>Importe Exonerado</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>No. Registro SAG: {no_registro_sag}</td>
              <td colSpan={2}>Importe Exento</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td rowSpan={5} colSpan={2}>
                <div className="impresion_titulo">
                  {empresa_titulo_impresion}
                </div>
                <div className="factura_detalle">
                  <div className="factura_datos">
                    <div className="factura_codigos">
                      <div className="factura">
                        <span className="text-bold">FACTURA</span>
                        <span>{empresa_numero_factura}{no_factura}</span>
                      </div>
                      <div className="rango_autorizado">
                        <div className="title">Rango Autorizado</div>
                        <div className="value">{empresa_rango_autorizado}</div>
                      </div>
                      <div className="fecha_limite">
                        <div className="text-bold">
                          Fecha Limite de Emision:{" "}
                          {empresa_fecha_limite_emision}
                        </div>
                      </div>
                    </div>
                    <div className="factura_no">
                      {/* <span>&#8470; {no_factura}</span> */}
                    </div>
                    <div className="factura_tipo">
                      <div className="item">
                        <span className="text">CONTADO</span>
                        <div className="check_box">
                          {credito === false ? <span>&#10003;</span> : ""}
                        </div>
                      </div>
                      <div className="item">
                        <span className="text">CREDITO</span>
                        <div className="check_box">
                          {credito === true ? <span>&#10003;</span> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="factura_footer">
                  <div className="message">
                    La factura es beneficio de todos "Exijala"
                  </div>
                  <div className="cai">cai: {empresa_cai}</div>
                </div>
              </td>
              <td colSpan={2}>ISV 15%</td>
              <td>L. {getNumberFormatted(values.impuesto)}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>ISV: 18%</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>Descuentos y Rebajas</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>Alicuota</td>
              <td>L.</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>Total a Pagar</td>
              <td>L. {getNumberFormatted(values.total)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="w-100 facturacion-footer">
          <div className="valor-letras">
            <div className="text">Valor en Letras:</div>
            <div className="line">{valor_en_letras}</div>
          </div>
          <div className="message">No se aceptan devoluciones</div>
        </div>
        {show_client_copy && (
          <div className="w-100">
            <span className="d-block" style={{
              marginTop: '10px',
              fontWeight: 'bold'
            }}>
              COPIA DE CLIENTE
            </span>
          </div>
        )}
      </div>
    );
  };

  render() {
    let quoteData = this.getQuoteData();
    return (
      <div
        className="modal no-padding modal-big modal-cotizacion"
        id={this.props.modal_id}
        style={{
          width: "1100px",
        }}
      >
        <div className="modal-content no-padding">
          <div
            className="card sticky-action"
            style={{
              width: "100%",
              margin: "0",
            }}
          >
            <div
              className="card-content"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="div_reporte">{quoteData}</div>
            </div>
            <div
              className="card-action"
              style={{ marginBottom: "0", height: "50px" }}
            >
              <a
                href="#!"
                className="btn-flat left"
                onClick={this.onCancelPrint}
              >
                Cerrar
              </a>

              <a href="#!" className="activator btn-flat">
                Editar informacion
              </a>

              <a href="#!" className="btn-flat" onClick={this.adjustAllItems}>
                Actualizar vista
              </a>

              <a
                href="#!"
                className="btn"
                style={{ marginRight: "7px" }}
                onClick={this.onShowProofDelivery}
              >
                Copia de cliente
              </a>

              <a href="#!" className="btn" onClick={this.onPrintClick}>
                Imprimir
              </a>
            </div>
            <div className="card-reveal">
              <span className="card-title grey-text text-darken-4">
                Editar informacion<i className="material-icons right">close</i>
              </span>

              <span className="d-block mt-1">Informacion de la empresa</span>

              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="empresa_nombre"
                    label="Nombre de empresa"
                    onchange={this.onChangeTextInput}
                    value={this.state.empresa_nombre}
                  />
                </div>
                <div className="col s12 m6">
                  <TextInputField
                    id="empresa_nombre_print"
                    label="Producto entregado en"
                    onchange={this.onChangeTextInput}
                    value={this.state.empresa_nombre_print}
                  />
                </div>
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_ubicacion"
                  label="Ubicacion"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_ubicacion}
                />
              </div>

              <div className="row">
                <TextInputField
                  id="empresa_telefono"
                  label="Telefono"
                  onchange={this.onChangeTextInput}
                  value={this.state.empresa_telefono}
                />
              </div>

              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="empresa_email"
                    type="email"
                    label="Email"
                    onchange={this.onChangeTextInput}
                    value={this.state.empresa_email}
                  />
                </div>
                <div className="col s12 m6">
                  <TextInputField
                    id="empresa_rtn"
                    label="RTN"
                    onchange={this.onChangeTextInput}
                    value={this.state.empresa_rtn}
                  />
                </div>
              </div>

              <span className="d-block mt-1">Informacion de la factura</span>

              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="no_orden_compra_excenta"
                    label="No. de Orden de compra excenta"
                    onchange={this.onChangeTextInput}
                    value={this.state.no_orden_compra_excenta}
                  />
                </div>
                <div className="col s12 m6">
                  <TextInputField
                    id="no_constancia_registro_exonerado"
                    label="No. Constancia de registro de exonerado"
                    onchange={this.onChangeTextInput}
                    value={this.state.no_constancia_registro_exonerado}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="no_registro_sag"
                    label="No. Registro SAG"
                    onchange={this.onChangeTextInput}
                    value={this.state.no_registro_sag}
                  />
                </div>
                <div className="col s12 m6">
                  <TextInputField
                    id="valor_en_letras"
                    label="Valor en letras"
                    onchange={this.onChangeTextInput}
                    value={this.state.valor_en_letras}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="no_orden_compra"
                    label="Orden de compra"
                    onchange={this.onChangeTextInput}
                    value={this.state.no_orden_compra}
                  />

                </div>
              </div>

              <span className="d-block mt-1">Informacion del cliente</span>
              <div className="row">
                <div className="col s12 m6">
                  <TextInputField
                    id="cliente_nombre"
                    label="Nombre del cliente"
                    onchange={this.onChangeTextInput}
                    value={this.state.cliente_nombre}
                  />
                </div>
                <div className="col s12 m6">
                  <TextInputField
                    id="cliente_rtn"
                    label="RTN del cliente"
                    onchange={this.onChangeTextInput}
                    value={this.state.cliente_rtn}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <TextInputField
                    id="cliente_direccion"
                    label="Direccion"
                    onchange={this.onChangeTextInput}
                    value={this.state.cliente_direccion}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrintSaleModal.propTypes = {
  modal_id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  venta: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  getGlobalVariables: PropTypes.func.isRequired,
};
PrintSaleModal.defaultProps = {
  modal_id: "modal_imprimir_venta",
};

const mapStateToProps = (state) => ({
  global: state.global,
  user: state.user,
});

export default connect(mapStateToProps, { getGlobalVariables })(PrintSaleModal);
