import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavbarAdmin from "../../../layout/NewNavbarAdmin";
import uuid from "uuid";

import {
  configMaterialComponents,
  removeMaterialComponents,
  configSelectInputFields,
  notificationError,
} from "../../../../utils/MaterialFunctions";

import Spinner from "../../../common/Spinner";
import SelectInputField from "../../../common/SelectInputField";
import CheckInputField from "../../../common/CheckInputField";

import { getUsers } from "../../../../actions/UserActions";
import { getLocals } from "../../../../actions/LocalActions";
import { getKardexResults } from "../../../../actions/kardexActions";

import ButtonField from "../../../common/ButtonField";
import TextInputField from "../../../common/TextInputField";
import EmptyIcon from "../../../common/EmptyIcon";
import { jsonToExcel } from "../../../../utils/jsonExcel";

class Kardex extends Component {
  state = {
    id_local: "0",
    id_producto: "0",
    id_usuario: "0",
    fecha_desde: "",
    fecha_hasta: "",
    localOptions: [],
    productOptions: [],
    userOptions: [],
    products: [],
    loading: false,
    group_products: false,
    group_digits: 3,
  };
  need_update_selects = false;

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getUsers();
    this.props.getLocals();
  }

  componentDidUpdate() {
    if (this.need_update_selects) {
      configSelectInputFields();
      this.need_update_selects = false;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.users && this.state.userOptions.length == 0) {
      this.setState({
        userOptions: nextProps.user.users.map((u) => ({
          value: u.id,
          label: u.nombre,
        })),
      });
      this.need_update_selects = true;
    }
    if (nextProps.local.locals && this.state.localOptions.length == 0) {
      this.setState({
        localOptions: nextProps.local.locals.map((u) => ({
          value: u.id,
          label: u.nombre,
        })),
      });
      this.need_update_selects = true;
    }
  }

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeCheckField = (e) => {
    const current_value = this.state[e.target.name];
    this.setState({ [e.target.name]: !current_value });
  };

  onSearchClick = () => {
    const { id_local, id_usuario, fecha_desde, fecha_hasta } = this.state;
    this.props.getKardexResults({
      fecha_desde,
      fecha_hasta,
      id_local,
      id_usuario,
    });
  };

  onExportExcelClick = () => {
    const { results } = this.props.kardex;
    if (results.length == 0)
      return notificationError("No hay registros para exportar");

    const arrayToExport = [];

    results.forEach((r) => {
      let cambio = "NA";
      if (r.incremento > r.decremento) {
        cambio = `+${r.incremento}`;
      } else if (r.decremento > r.incremento) {
        cambio = `-${r.decremento}`;
      }

      arrayToExport.push({
        id_local: r.id_local,
        id_producto: r.id_producto,
        local: r.local.nombre,
        usuario: r.usuario.nombre,
        codigo: r.producto.codigo_barra,
        producto: r.producto.nombre,
        precio: r.producto.precio,
        ubicacion: r.ubicacion,
        cambio,
        inventario_inicial: r.inicial,
        inventario_tienda: r.total,
        fecha: r.fecha_creado,
      });
    });

    jsonToExcel(
      arrayToExport,
      {
        id_local: "ID Local",
        id_producto: "ID Producto",
        local: "Local",
        usuario: "Usuario",
        codigo: "Codigo",
        producto: "Producto",
        precio: "Precio",
        ubicacion: "Ubicacion",
        cambio: "Cambio",
        inventario_inicial: "Inventario inicial en tienda",
        inventario_tienda: "Inventario final en tienda",
        fecha: "Fecha",
      },
      "Rimeim kardex"
    );
  };

  getInputForm = () => {
    const {
      id_local,
      id_usuario,
      localOptions,
      userOptions,
      fecha_desde,
      fecha_hasta,
    } = this.state;
    return (
      <>
        <div className="row">
          <div className="col s12 m3">
            <SelectInputField
              id="id_local"
              value={id_local}
              label="Local"
              options={localOptions}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="col s12 m3">
            <SelectInputField
              id="id_usuario"
              value={id_usuario}
              label="Usuario"
              options={userOptions}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="col s12 m3">
            <TextInputField
              id="fecha_desde"
              value={fecha_desde}
              label="Fecha inicial"
              type="date"
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="col s12 m3">
            <TextInputField
              id="fecha_hasta"
              value={fecha_hasta}
              label="Fecha final"
              type="date"
              onchange={this.onChangeTextInput}
            />
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <div className="btn-2">
              <ButtonField
                text="Buscar"
                className="btn"
                onClick={this.onSearchClick}
              />
              <ButtonField
                text="Exportar Excel"
                className="btn"
                onClick={this.onExportExcelClick}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  getProductChange = (product) => {
    if (product.incremento > product.decremento) {
      return <span className="added-value">+ {product.incremento}</span>;
    } else if (product.decremento > product.incremento) {
      return <span className="minus-value">- {product.decremento}</span>;
    }
    return <span>NA</span>;
  };

  getProductsTable = (results = []) => {
    return (
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <table className="table striped">
          <thead>
            <tr>
              <th>Local</th>
              <th>Usuario</th>
              <th>Codigo</th>
              <th>Producto</th>
              <th>Ubicacion</th>
              <th>Cambio</th>
              <th>Inventario en tienda</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>{this.getTableBodyResultsRow(results)}</tbody>
        </table>
      </div>
    );
  };

  getTableBodyResultsRow = (results = [], addMaxWidht = false) => {
    return results.map((p) => (
      <tr key={uuid()}>
        <td className={`${addMaxWidht ? "kardex-row-local" : ""}`}>
          {p.local.nombre}
        </td>
        <td>{p.usuario.nombre}</td>
        <td>{p.producto.codigo_barra}</td>
        <td className={`${addMaxWidht ? "kardex-row-product" : ""}`}>
          <a href={`productos/${p.id_producto}`} target={"_blank"}>
            {p.producto.nombre}
          </a>
        </td>
        <td>{p.ubicacion}</td>
        <td>{this.getProductChange(p)}</td>
        <td>{p.total}</td>
        <td>{p.fecha_creado}</td>
      </tr>
    ));
  };

  getGroupedProductsContent = (results = []) => {
    const groupedObject = {};
    const groupedArray = [];

    results.forEach((r) => {
      const key = r.ubicacion.slice(0, 3);
      if (!groupedObject[key]) {
        groupedObject[key] = {
          key,
          results: [],
        };
      }
      groupedObject[key].results.push(r);
    });

    Object.keys(groupedObject).forEach((key) => {
      groupedArray.push(groupedObject[key]);
    });
    groupedArray.sort((a, b) => a.key > b.key);

    return (
      <>
        <div>
          <h5>Prefijos</h5>
          <div className="prefix-items">
            {groupedArray.map((item) => (
              <div key={uuid()} className="prefix-item">
                <a
                  href={`#${item.key}`}
                  className="waves-effect waves-teal btn"
                >
                  {item.key} - {item.results.length}
                </a>
              </div>
            ))}
          </div>
        </div>
        <ul className="collection">
          {groupedArray.map((item) => (
            <li className="collection-item" key={uuid()} id={item.key}>
              <div
                style={{
                  overflowX: "auto",
                }}
              >
                <h5>
                  Prefijo: {item.key}, Cambios: {item.results.length}
                </h5>
                <table className="table striped">
                  <thead>
                    <tr>
                      <th>Local</th>
                      <th>Usuario</th>
                      <th>Codigo</th>
                      <th>Producto</th>
                      <th>Ubicacion</th>
                      <th>Cambio</th>
                      <th>Inventario en tienda</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getTableBodyResultsRow(item.results, true)}
                  </tbody>
                </table>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  getProductsContent = () => {
    const { loading, results } = this.props.kardex;
    if (loading) {
      return <Spinner />;
    } else if (!loading && results.length == 0) {
      return <EmptyIcon message="No hay resultados para mostrar" />;
    }
    const { group_products } = this.state;
    let resultsContent;

    if (group_products) {
      resultsContent = this.getGroupedProductsContent(results);
    } else {
      resultsContent = this.getProductsTable(results);
    }

    return (
      <div className="card">
        <div className="card-content">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="card-title">
              Cambios recientes: {results.length}
            </div>
            <div>
              <CheckInputField
                id="group_products"
                label="Agrupar productos"
                checked={group_products}
                onchange={this.onChangeCheckField}
                className=""
                useCol={false}
              />
            </div>
          </div>
          {resultsContent}
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <NavbarAdmin>
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Kardex
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#!">
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NavbarAdmin>

        <main>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  <div className="card-title">
                    Parametros para observar cambios en el inventario
                  </div>
                  {this.getInputForm()}
                </div>
              </div>
              {this.getProductsContent()}
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

Kardex.propTypes = {
  kardex: PropTypes.object.isRequired,
  local: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getKardexResults: PropTypes.func.isRequired,
  getLocals: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  kardex: state.kardex,
  local: state.local,
  user: state.user,
});

export default connect(mapStateToProps, {
  getKardexResults,
  getLocals,
  getUsers,
})(Kardex);
