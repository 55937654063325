export const getCurrentDateToObject = () => {
  const today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return {
    year: yyyy,
    month: mm,
    day: dd,
  };
};

export const getCurrentDateToInput = () => {
  const today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return `${yyyy}-${mm}-${dd}`;
};

export const dateToString = (dateObj) => {
  var dd = dateObj.getDate();
  var mm = dateObj.getMonth() + 1;
  var yyyy = dateObj.getFullYear();
  var hours = dateObj.getHours();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return `${yyyy}-${mm}-${dd} ${hours}:00`;
};

export const dateToObject = (dateStr) => {
  const date = new Date(dateStr);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return {
    year: yyyy,
    month: mm,
    day: dd,
  };
};

export const transformDateToShow = (date) => {};

export const getDaysDiffDate = (fecha_inicio, fecha_fin) => {
  let dias = 0;
  try {
    dias = Math.round((fecha_fin - fecha_inicio) / (1000 * 60 * 60 * 24));
  } catch (err) {}
  return dias;
};
