import React, { Component } from "react";
import { connect } from "react-redux";
import NewNavbar from "../../layout/NewNavbar";
// import uuid from 'uuid';
import PropTypes from "prop-types";

import "../../../public/css/ventas.css";
import "../../../public/css/impresion.css";

// Functions
import {
  configMaterialComponents,
  removeMaterialComponents,
  getModalInstanceById,
} from "../../../utils/MaterialFunctions";

import {
  getSellById,
  clearSellState,
  addDaysToCredit,
  markCreditFinished,
  reorderSellProducts,
  updateSellProducts,
} from "../../../actions/sellActions";
import ShowSaleCard from "../../common/ShowSale";
import ConfirmationModal from "../../layout/modals/ConfirmationModal";
import TextInputField from "../../common/TextInputField";

import PrintSaleModal from "../../layout/modals/PrintSale";

class ShowSale extends Component {
  state = {
    dias_credito_add: "0",
  };

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getSellById(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.clearSellState();
  }

  onAddDaysClick = () => {
    getModalInstanceById("modal_add_days_to_credit").open();
  };

  onConfirmAddCreditDays = () => {
    const { dias_credito_add } = this.state;
    if (parseInt(dias_credito_add) > 0) {
      this.props.addDaysToCredit(this.props.match.params.id, dias_credito_add);
    }
    getModalInstanceById("modal_add_days_to_credit").close();
  };

  onFinisCreditClick = () => {
    getModalInstanceById("modal_mark_finish_credit").open();
  };

  onConfirmFinishCredit = () => {
    this.props.markCreditFinished(this.props.match.params.id);
    getModalInstanceById("modal_mark_finish_credit").close();
  };

  getModalAddDays = () => {
    const { dias_credito_add } = this.state;
    return (
      <div className="modal" id="modal_add_days_to_credit">
        <div className="modal-content">
          <h5>Agregar dias al credito</h5>
          <div className="row">
            <TextInputField
              id="dias_credito_add"
              label="Dias adicionales de credito"
              onchange={this.onChangeTextInput}
              value={dias_credito_add}
              type="number"
              active_label={true}
            />
          </div>
        </div>
        <div className="modal-footer">
          <a href="#!" className="btn-flat left modal-close">
            Cancelar
          </a>
          <button className="btn right" onClick={this.onConfirmAddCreditDays}>
            Aceptar
          </button>
        </div>
      </div>
    );
  };

  onOrderProducts = () => {
    this.props.reorderSellProducts();
  };

  onUpdateSellProducts = () => {
    const { sell } = this.props.sell;
    this.props.updateSellProducts(sell.id, sell.productos);
  };

  getSaleToPrint = (sell) => {
    let productos = {};
    let values = { subtotal: 0, impuesto: 0, total: 0 };
    let cliente = {};
    let no_factura = "NA";

    if (sell.productos) {
      productos.productos = sell.productos;
    }
    if (sell.sub_total && sell.impuesto && sell.total) {
      values.subtotal = sell.sub_total;
      values.impuesto = sell.impuesto;
      values.total = sell.total;
    }
    if (sell.cliente) {
      cliente.nombre = sell.cliente.nombre;
      cliente.rtn = sell.cliente.rtn;
    }

    if (sell.no_factura) {
      no_factura = `${sell.no_factura}`;
      while (no_factura.length < 6) {
        no_factura = `0${no_factura}`;
      }
    }

    return {
      id: this.props.sell.sell.id,
      productos: productos,
      no_factura: no_factura,
      values: values,
      cliente: cliente,
      local: this.props.user.currentLocal,
      credito: sell.metodo_pago === "credito",
      fecha: sell.fecha_creado,
    };
  };

  render() {
    const { loading, sell } = this.props.sell;
    return (
      <React.Fragment>
        <NewNavbar active_nav="VENTAS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Venta
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#modal_imprimir_venta" className="modal-trigger">
                  <i className="material-icons">print</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">
            <div className="col s12">
              <ShowSaleCard
                loading={loading}
                sale={sell}
                addCreditDays={this.onAddDaysClick}
                finishCredit={this.onFinisCreditClick}
                onOrderProducts={this.onOrderProducts}
                onUpdateSellProducts={this.onUpdateSellProducts}
              />
            </div>
          </div>
        </main>

        {this.getModalAddDays()}

        <ConfirmationModal
          id="modal_mark_finish_credit"
          title="Finalizar credito"
          message="Marcar este credito como pagado?"
          onAccept={this.onConfirmFinishCredit}
        />

        <PrintSaleModal
          modal_id="modal_imprimir_venta"
          venta={this.getSaleToPrint(sell)}
          onCancel={() => { }}
        />
      </React.Fragment>
    );
  }
}

ShowSale.propTypes = {
  sell: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getSellById: PropTypes.func.isRequired,
  clearSellState: PropTypes.func.isRequired,
  addDaysToCredit: PropTypes.func.isRequired,
  markCreditFinished: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sell: state.sell,
  user: state.user,
});

export default connect(mapStateToProps, {
  getSellById,
  clearSellState,
  addDaysToCredit,
  markCreditFinished,
  reorderSellProducts,
  updateSellProducts,
})(ShowSale);
